export default {
    purchasesBill: {
        deleteSuccess: 'Bill has been deleted successfully',
        deleteModal: {
            title: 'Delete Bill',
            description: 'Are you sure you want to delete this bill?',
        },
        deleteFailureModal: {
            title: 'Deletion Failure',
            description:
                'Bill cannot be deleted because it has credit notes applied to it or it is already paid.',
        },
        btnFinish: 'Finish',
        emptyText: 'No bill found',
        emptySubText: 'Go ahead an create your first bill',
        title: 'Bills',
        uploadBill: 'Upload Bill',
        searchByReferenceNumber: 'Search by reference number',
        viewAll: 'View All',
        draft: 'Draft',
        open: 'Open',
        overdue: 'Overdue',
        partiallyPaid: 'Partially paid',
        paid: 'Paid',
        saveAsDraft: 'Save as draft',
        saveAsOpen: 'Save as open',
        overdueBy: (item) => {
            const days = item.named('days')

            if (days === 0) {
                return `Overdue today`
            }

            return `Overdue by ${days} days`
        },
        columns: {
            date: 'Date',
            bill: 'Bill#',
            referenceNumber: 'Reference Number',
            vendorName: 'Vendor Name',
            status: 'Status',
            dueDate: 'Due Date',
            amount: 'Amount',
            balanceDue: 'Balance Due',
        },

        filters: {
            supplier: 'Supplier',
            type: 'Type',
            supplierPlaceholder: 'Search supplier',
            dateOfDocument: 'Date of document',
            payDate: 'Pay date',
            document: 'Document',
            amountNet: 'Amount (Net)',
            flag: 'Flag',
            flagged: 'Flagged',
            unFlagged: 'Unflagged',
        },

        create: {
            title: 'New Bill',
            success: 'Bill created successfully',
        },

        edit: {
            success: 'Bill updated successfully',
            title: 'Edit bill',
            headline: 'Edit expenditure',
            description: 'A bill can be a expenditure, or a revenue',
            buttonSaveAsDraft: 'Save as draft',
            expenditureDocument: {
                label: 'Expenditure document',
                text1: 'PDF: 1 one-page or multi-page PDF can be uploaded',
                text2: 'PNG/JPG: One or more files can be uploaded at the same time',
                fileUploadDescription: 'PNG, JPG or PDF (max. 800x400px)',
            },
            documentNumber: {
                label: 'Document number *',
                placeholder: 'e.g. invoice number',
            },
            documentDate: {
                label: 'Date of document *',
            },
            supplier: {
                label: 'Supplier *',
                placeholder: 'Search supplier',
            },
            link: {
                label: 'Link',
                placeholder: 'Search contact',
            },
            dueDate: {
                label: 'Due date',
            },
            paymentTerms: {
                label: 'Payment Terms',
            },
            positions: {
                label: 'Positions',
            },
            lineItem: {
                chartOfAccount: {
                    label: 'Chart of Account *',
                    placeholder: 'Select chart of account',
                },
                description: {
                    label: 'Description',
                },
                amount: {
                    label: 'Amount *',
                },
                taxUnit: '%',
            },
            addLineItemBtn: 'Add line item to bill',
            tax: {
                label: 'VAT rule',
                taxRule1: 'VAT rule 1',
                taxRule2: 'VAT rule 2',
                taxRule3: 'VAT rule 3',
                addTaxBtn: 'Add tax rate',
            },
            summary: {
                netAmount: 'Net amount',
                net: 'Net amount (inc. discount/surcharge)',
                tax: 'VAT',
                total: 'Total',
            },
        },

        finish: {
            confirm_edit_modal: {
                headline: 'Edit bill',
                description: 'Are you sure you want to edit this bill?',
            },
            today: 'Today',
            new_expenditure: 'New expenditure',
            new_revenue: 'New revenue',
            book_payment: 'Book outgoing payment',
            payment_title: 'Payments',
            due_date: 'Due date',
            unpaid_amount: 'Unpaid amount',
            payment_date: 'Payment date',
            date_of_document: 'Date of document',
            supplier: 'Supplier',
            document_number: 'Document number',
            line_items_title: 'Line items',
            description: 'Description',
            chartOfAccount: 'Chart of Account',
            depreciation: 'Depreciation',
            amount: 'Amount',
            vat_rule: 'VAT rule',
            vat_rate: 'VAT rate',
            net_amount: 'Net amount (inc. discount/surcharge)',
            vat: 'VAT',
            total: 'Total',
            incl: 'incl.',
            excl: 'excl.',
        },

        detail: {
            title: 'Bill no. {number}',
            downloadBtn: 'PDF',
            recordPaymentBtn: 'Record a payment',
            paymentsMade: 'Payments Made',
            selectedText: '{count} selected',
            today: 'Today',
            convertSuccess: 'Bill converted to Open successfully',
            sidebar: {
                filter: {
                    allBills: 'All bills',
                    draft: 'Draft',
                    open: 'Open',
                    due: 'Overdue',
                    partPaid: 'Partially paid',
                    paid: 'Paid',
                },
            },
            convertOpenNotice: {
                title: 'Convert to Open',
                description:
                    'Bill has been created. Convert the bill to the open status to record payment.',
                convertBtn: 'Convert to Open',
            },
            info: {
                description: 'Description',
                account: 'Account',
                rate: 'Rate',
                amount: 'Amount',
            },
            paymentDetail: {
                amount: 'Amount',
                supplier: 'Supplier',
                billDate: 'Bill Date',
                dueDate: 'Due Date',
                paymentTerms: 'Payment Terms',
            },
            overdueAlert: {
                title: 'Record Payment',
                description:
                    'Payment for this bill is overdue. You can record the payment for this bill if paid.',
            },
        },

        lineItems: {
            chartOfAccount: {
                label: 'Expense Account *',
                placeholder: 'Select chart of account',
            },
            description: {
                label: 'Description *',
            },
            amount: {
                label: 'Amount *',
            },
            quantity: {
                label: 'Quantity *',
            },
            unit: {
                label: 'Unit',
            },
            tax: {
                label: 'Tax *',
                placeholder: 'Select a Tax',
            },
        },

        applyCreditModal: {
            title: 'Apply credits for {number}',
            creditNoteNoColumn: 'Credit note#',
            dateColumn: 'Date',
            creditAmountColumn: 'Amount',
            creditsAvailableColumn: 'Available balance',
            amountToCreditColumn: 'Amount applied',
            amountToCreditText: 'Total amount applied: {amount}',
            billBalance: 'Final bill balance: {amount}',
            applySuccessMsg: 'Credits have been applied to the bill(s)',
        },

        appliedCredits: {
            title: 'Credits Applied',
            date: 'Date',
            creditNo: 'Vendor credit#',
            creditsApplied: 'Amount Credited',
            deleteSuccess: 'Credits have been removed from the bill',
            deleteConfirmText:
                'Are you sure you want to delete the credits applied to bill?',
        },
    },
}
