<template>
    <base-context-menu>
        <base-context-menu-item
            v-if="$acl.can('delete_bills')"
            icon="line-icons:general:trash-01"
            @click="onDeleteBill"
        >
            {{ $t('general.delete') }}
        </base-context-menu-item>
    </base-context-menu>
</template>

<script setup>
const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
})

const emit = defineEmits(['delete-bill'])

const onDeleteBill = () => {
    emit('delete-bill', props.item.id)
}
</script>
