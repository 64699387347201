<template>
    <base-button-group>
        <base-button
            v-for="(tab, index) in Object.values(tabs)"
            :key="index"
            variant="default"
            :class="{
                'bg-gray-100': isActive(tab),
            }"
            @click="viewChange(tab)"
        >
            {{ tab.label }}
        </base-button>
    </base-button-group>
</template>

<script setup>
import { BILL_STATUS } from '@tenant/modules/tenant/purchases-bill/utils/constants'
import { dayjsFormat, now } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'

const { t } = useI18n()

const tabs = {
    all: {
        value: null,
        label: t('purchasesBill.viewAll'),
    },
    draft: {
        value: BILL_STATUS.DRAFT,
        label: t('purchasesBill.draft'),
    },
    open: {
        value: BILL_STATUS.OPEN,
        label: t('purchasesBill.open'),
    },
    overdue: {
        value: BILL_STATUS.DUE,
        label: t('purchasesBill.overdue'),
    },
    partPaid: {
        value: BILL_STATUS.PART_PAID,
        label: t('purchasesBill.partiallyPaid'),
    },
    paid: {
        value: BILL_STATUS.PAID,
        label: t('purchasesBill.paid'),
    },
}

const queries = {
    statuses: 'statuses',
    due_date: 'filter.due_date',
}
const status = ref(null)

const { filterValues, updateFilters } = useFilters(queries)

onMounted(() => {
    initStatus()
})

const initStatus = () => {
    const { statuses, due_date } = filterValues.value
    if (!statuses) {
        return
    }

    if (Array.isArray(statuses) && statuses.length > 0) {
        const values = statuses[0] || []

        if (values.includes(BILL_STATUS.OPEN) && due_date?.[0] === 'DATE <') {
            status.value = BILL_STATUS.DUE
            return
        }
    }

    status.value = statuses[0]
}

const viewChange = ({ value }) => {
    const filters = {
        [queries.statuses]: !value ? [] : [value],
        [queries.due_date]: null,
    }

    if (value === BILL_STATUS.OPEN) {
        filters[queries.statuses] = [BILL_STATUS.OPEN]
        filters[queries.due_date] = [
            'DATE >=',
            dayjsFormat(now(), DATE_SERVER_FORMAT),
        ]
    }

    if (value === BILL_STATUS.DUE) {
        filters[queries.statuses] = [BILL_STATUS.OPEN]
        filters[queries.due_date] = [
            'DATE <',
            dayjsFormat(now(), DATE_SERVER_FORMAT),
        ]
    }

    status.value = value

    updateFilters(filters)
}

const isActive = (tab) => {
    return status.value === tab.value
}
</script>
