<template>
    <div class="flex h-full" v-if="item">
        <purchases-bill-sidebar-list
            :bill="item"
            @data-update="onDataUpdated"
        />

        <div class="flex h-full grow flex-col">
            <base-sticky-heading :title="headline" class="min-w-92">
                <template #right>
                    <div class="flex gap-3">
                        <base-button
                            v-if="$acl.can('update_bills')"
                            variant="default"
                            icon="line-icons:editor:pencil-01"
                            size="sm"
                            :label="$t('general.edit')"
                            @click="
                                router.push({
                                    name: 'purchases.bill.edit',
                                    params: {
                                        id: item.id,
                                    },
                                })
                            "
                        />

                        <base-button
                            variant="default"
                            icon="line-icons:files:file-download-03"
                            size="sm"
                            :label="$t('purchasesBill.detail.downloadBtn')"
                            @click="onDownload"
                        />

                        <base-button
                            v-if="
                                ![BILL_STATUS.DRAFT, BILL_STATUS.PAID].includes(
                                    item.status
                                ) && $acl.can('update_bills')
                            "
                            icon="line-icons:finance-&-ecommerce:bank-note-01"
                            size="sm"
                            :label="$t('purchasesBill.detail.recordPaymentBtn')"
                            @click="handleRecordPayment"
                        />

                        <bills-context-menu
                            item="item"
                            @delete-bill="openDeleteModal"
                        />
                    </div>
                </template>

                <div class="py-6">
                    <base-alert
                        v-if="
                            item.status === BILL_STATUS.DRAFT &&
                            $acl.can('update_bills')
                        "
                        class="m-6 mt-0"
                        variant="primary"
                        :title="
                            $t('purchasesBill.detail.convertOpenNotice.title')
                        "
                    >
                        <template #description="{ classes }">
                            <div class="flex flex-col gap-2.5 2xl:flex-row">
                                <p
                                    class="flex-1 text-sm font-normal"
                                    :class="classes"
                                >
                                    {{
                                        $t(
                                            'purchasesBill.detail.convertOpenNotice.description'
                                        )
                                    }}
                                </p>

                                <div
                                    class="flex justify-start gap-4 2xl:justify-end"
                                >
                                    <base-button @click="onConvert">
                                        {{
                                            $t(
                                                'purchasesBill.detail.convertOpenNotice.convertBtn'
                                            )
                                        }}
                                    </base-button>
                                </div>
                            </div>
                        </template>
                    </base-alert>

                    <div class="px-6 pb-6" v-if="isDue">
                        <base-alert
                            variant="primary"
                            :title="
                                $t('purchasesBill.detail.overdueAlert.title')
                            "
                            class="relative"
                        >
                            <template #description="{ classes }">
                                <div class="flex justify-between">
                                    <p
                                        class="flex-1 text-sm font-normal"
                                        :class="classes"
                                    >
                                        {{
                                            $t(
                                                'purchasesBill.detail.overdueAlert.description'
                                            )
                                        }}
                                    </p>

                                    <base-icon
                                        class="absolute right-4 top-0 !h-full !w-[72px]"
                                        name="other:arrow-indicator"
                                    />
                                </div>
                            </template>
                        </base-alert>
                    </div>

                    <div id="purchases-bill-detail">
                        <div class="px-6 pb-6">
                            <div
                                v-if="
                                    availableCredit &&
                                    defaultCurrency &&
                                    [
                                        BILL_STATUS.OPEN,
                                        BILL_STATUS.PART_PAID,
                                    ].includes(item?.status)
                                "
                                class="flex items-center rounded-md border border-gray-200 p-4 text-sm text-gray-900"
                            >
                                <p
                                    v-html="
                                        $filters.sanitize(
                                            $t(
                                                'invoices.dispatch.creditsAvailableText',
                                                {
                                                    amount: $filters.symbolCurrency(
                                                        availableCredit,
                                                        defaultCurrency
                                                    ),
                                                }
                                            )
                                        )
                                    "
                                ></p>
                                <div
                                    class="ml-2 cursor-pointer text-primary-700"
                                    @click="showApplyModal = true"
                                >
                                    {{ $t('general.applyNow') }}
                                </div>
                            </div>
                        </div>

                        <div
                            class="px-6 pb-6"
                            v-if="
                                item?.status !== BILL_STATUS.DRAFT &&
                                item?.vendor_credits?.length
                            "
                        >
                            <purchases-bill-detail-payment-collapse
                                :bill="item"
                                @data-update="onDataUpdated"
                            />
                        </div>

                        <purchases-bill-detail-info v-if="item" :bill="item" />

                        <div class="mx-6 mb-6 ml-auto w-[290px]">
                            <purchases-bill-detail-summary />
                        </div>

                        <div class="mb-6 px-6">
                            <journal-history-collapse
                                v-if="item?.status !== BILL_STATUS.DRAFT"
                                :loading="isLoading"
                                :queries="{
                                    filter: {
                                        'journalEntry.journalable_id':
                                            $route.params.id,
                                        'journalEntry.transaction_type':
                                            JOURNAL_TRANSACTION_TYPE.BILL,
                                    },
                                }"
                            />
                        </div>
                    </div>
                </div>
            </base-sticky-heading>
        </div>
    </div>

    <purchases-bill-finish-book-outgoing-payment-modal
        v-if="item"
        :show="showBookOutgoingPaymentModal"
        :item="item"
        @modal-close="showBookOutgoingPaymentModal = false"
        @modal-confirm="onRecordPayment"
    />

    <no-payment-account-modal
        :show="showNoPaymentAccountModal"
        @modal-close="showNoPaymentAccountModal = false"
    />

    <bills-delete-modal
        v-if="item"
        :bill-id="item.id"
        :loading="loadingDeleteBills"
        :show="showDeleteModal"
        @modal-close="showDeleteModal = false"
        @modal-confirm="onDeleteBill"
    />

    <bills-delete-failure-modal
        :show="showCannotDeleteModal"
        @modal-close="showCannotDeleteModal = false"
    />

    <purchases-bill-apply-credit-modal
        v-if="showApplyModal"
        :bill="item"
        :show="showApplyModal"
        @modal-close="showApplyModal = false"
        @modal-save="onAppliedCredit"
        :key="item.id"
    />
</template>

<script setup>
import { useEmitter, useApiFactory } from '@tenant/composables'
import { dayDiff, now, shortServerFormat } from '@tenant/utils/day'
import { BILL_STATUS } from '@tenant/modules/tenant/purchases-bill/utils/constants'
import { JOURNAL_TRANSACTION_TYPE } from '@tenant/utils/constants'
import { downloadPDFFromElement } from '@tenant/utils/helper'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const emitter = useEmitter()

const { successNotify, errorNotify } = useNotification()
const { setValues } = useForm()
const { useApiGet, setEntity } = useApiFactory('bill')
const { execute: executeGet, loading: isLoading } = useApiGet()

const item = ref(null)
const showBookOutgoingPaymentModal = ref(false)
const showNoPaymentAccountModal = ref(false)

const headline = computed(() => {
    if (!item.value) {
        return ''
    }

    const { document_number: number } = item.value
    return t('purchasesBill.detail.title', { number })
})

useHead({
    title: computed(() => headline.value),
})

onMounted(async () => {
    await fetchBill()
})

const fetchBill = async () => {
    try {
        emitter.emit('set-loading', true)

        item.value = await executeGet(route.params.id)

        const {
            unpaid_amount,
            amount_gross_total,
            status,
            type,
            contact,
            tax_type,
            contact_id,
            document_number,
            supplier_name,
            date_of_document,
            due_date,
            currency_id,
            tax_rule_id,
            documents,
            line_items,
            tax_rule,
            currency,
            booked_transactions,
        } = item.value

        setValues({
            unpaid_amount,
            amount_gross_total,
            status,
            type,
            contact,
            tax_type,
            contact_id,
            document_number,
            supplier_name,
            date_of_document: shortServerFormat(date_of_document),
            due_date: shortServerFormat(due_date),
            currency_id,
            tax_rule_id,
            documents,
            line_items,
            currency,
            tax_rule,
            booked_transactions,
        })

        await getAvailableCredit({
            params: {
                id: route.params.id,
            },
        })
    } finally {
        emitter.emit('set-loading', false)
    }
}

const { paymentAccounts } = usePaymentAccounts(item.value?.currency_id)
const handleRecordPayment = () => {
    if (!paymentAccounts.value.length) {
        showNoPaymentAccountModal.value = true
        return
    }

    showBookOutgoingPaymentModal.value = true
}

const onRecordPayment = () => {
    showBookOutgoingPaymentModal.value = false
    successNotify({
        title: t('transaction.bookPaymentSuccess'),
    })

    fetchBill()
}
const onDataUpdated = async (cb) => {
    await fetchBill()
    if (cb && cb instanceof Function) {
        cb()
    }
}

const dueDate = useFieldValue('due_date')
const status = useFieldValue('status')

const isDue = computed(() => {
    return (
        status.value === BILL_STATUS.OPEN && dayDiff(dueDate.value, now()) <= 0
    )
})

watch(
    () => route.params.id,
    async (id) => {
        if (route.name !== 'purchases.bill.detail') {
            return
        }

        if (id) {
            await fetchBill()
        }
    }
)

// CONVERT TO OPEN
const { execute: executeConvertToOpen } = useApi(
    'api/bill/{id}/convert-to-open',
    'PATCH'
)

const onConvert = async () => {
    try {
        emitter.emit('set-loading', true)

        await executeConvertToOpen({
            params: {
                id: item.value.id,
            },
        })

        successNotify({
            text: t('purchasesBill.detail.convertSuccess'),
        })

        await fetchBill()
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('set-loading', false)
    }
}
// END: CONVERT TO OPEN

const documents = useFieldValue('documents')
const documentPath = computed(() => {
    if (!documents.value.length) {
        return ''
    }

    return documents.value[0]?.preview?.path
})

const { downloadFile } = useFileStorage()
const onDownload = async () => {
    if (documentPath.value) {
        await downloadFile(
            documentPath.value,
            `${headline.value ?? 'bill'}.pdf`
        )
    } else {
        downloadPDFFromElement(
            'purchases-bill-detail',
            `${headline.value ?? 'bill'}.pdf`
        )
    }
}

const { execute: executeDeleteBills, loading: loadingDeleteBills } = useApi(
    '/api/bill',
    'DELETE'
)

const showDeleteModal = ref(false)
const showCannotDeleteModal = ref(false)

const onDeleteBill = async (id) => {
    try {
        await executeDeleteBills({
            data: {
                ids: [id],
            },
        })

        successNotify({
            title: t('purchasesBill.deleteSuccess'),
        })

        await router.push({
            name: 'purchases.bill',
        })
    } catch ({ hasErrors, errorMessage }) {
        showCannotDeleteModal.value = true
        if (hasErrors) {
            errorNotify({
                title: errorMessage,
            })
        }
    } finally {
        showDeleteModal.value = false
    }
}

const openDeleteModal = (item) => {
    setEntity(item)
    showDeleteModal.value = true
}

// VENDOR CREDITS
const showApplyModal = ref(false)

const { execute: getAvailableCredit, result: availableCredit } = useApi(
    '/api/bill/{id}/credits-amount-available',
    'POST'
)
const { defaultCurrency } = useCurrencies()

const onAppliedCredit = async () => {
    await fetchBill()
    showApplyModal.value = false
}
// END: VENDOR CREDITS
</script>
