const navigation = [
    {
        id: 'sas-purchases-bill',
        path: 'purchases.bill',
        label: 'sidebar.purchases.bill',
        parent: 'sas-purchases',
        position: 2,
        permission: 'view_bills',
    },
]

export default navigation
