<template>
    <base-sticky-heading
        :title="$t('purchasesBill.title')"
        class="min-h-[82px] items-center"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    v-if="$acl.can('create_bills')"
                    variant="primary"
                    icon="line-icons/general/upload-01"
                    size="sm"
                    :label="$t('purchasesBill.uploadBill')"
                    @click="toggleUploadDocuments"
                />

                <base-button
                    v-if="$acl.can('create_bills')"
                    variant="primary"
                    icon="line-icons:general:plus"
                    size="sm"
                    :label="$t('general.new')"
                    @click="$router.push({ name: 'purchases.bill.create' })"
                />
            </div>
        </template>
        <bill-grid
            :queries="
                isEmpty($route.query)
                    ? {
                          sort: {
                              col: 'date_of_document',
                              by: 'desc',
                          },
                      }
                    : $route.query
            "
        />

        <document-extraction-upload-documents
            @close="toggleUploadDocuments"
            :show="state.showUploadDocuments"
        />
    </base-sticky-heading>
</template>

<script setup>
import { isEmpty } from 'lodash-es'

const { t } = useI18n()

useHead({
    title: t('purchasesBill.title'),
})

const state = reactive({
    showUploadDocuments: false,
})
const toggleUploadDocuments = () => {
    state.showUploadDocuments = !state.showUploadDocuments
}
</script>
