import { BILL_STATUS } from '@tenant/modules/tenant/purchases-bill/utils/constants'

export const getStatusVariant = (status) => {
    switch (status) {
        case BILL_STATUS.PAID:
        case BILL_STATUS.PART_PAID:
            return 'success'
        case BILL_STATUS.DUE:
            return 'warning'
        case BILL_STATUS.OPEN:
            return 'info'
        default:
            return 'gray'
    }
}
