<template>
    <div class="px-6">
        <p class="text-xs text-gray-700">
            {{ $t('purchasesBill.detail.paymentDetail.amount') }}
        </p>
        <div class="mb-2.5 flex items-end gap-1">
            <p class="text-lg text-danger-600">
                {{ $filters.symbolCurrency(grossTotal, currency) }}
            </p>
            <p class="text-xs font-medium text-gray-700">
                {{
                    $t('general.onDate', {
                        date: $filters.dateHumanFormat(dateOfDocument),
                    })
                }}
            </p>
        </div>

        <div class="mb-6">
            <base-badge
                v-if="status === BILL_STATUS.DUE"
                :label="
                    $t('purchasesBill.overdueBy', {
                        days: Math.abs($filters.dayDiff(dueDate)),
                    })
                "
                :variant="getStatusVariant(status)"
            />
            <base-badge
                v-else
                :label="$t('billStatus', { status: status })"
                :variant="getStatusVariant(status)"
            />
        </div>

        <div
            class="mb-12 grid grid-cols-[minmax(0px,100px)_1fr] gap-2.5 text-xs font-medium text-gray-700"
        >
            <p>{{ $t('purchasesBill.detail.paymentDetail.supplier') }}</p>
            <router-link
                :to="{
                    name: 'contacts.detail',
                    params: {
                        id: bill?.contact?.id,
                    },
                }"
                class="text-primary-600"
                :title="supplierName"
            >
                {{ supplierName }}
            </router-link>

            <p>{{ $t('purchasesBill.detail.paymentDetail.billDate') }}</p>
            <p>{{ $filters.dateHumanFormat(dateOfDocument) }}</p>

            <p>{{ $t('purchasesBill.detail.paymentDetail.dueDate') }}</p>
            <p
                :class="{
                    'text-danger-600': [
                        BILL_STATUS.DUE,
                        BILL_STATUS.PART_PAID,
                    ].includes(getStatus(status, dueDate)),
                    'text-gray-900': ![
                        BILL_STATUS.DUE,
                        BILL_STATUS.PART_PAID,
                    ].includes(getStatus(status, dueDate)),
                }"
            >
                {{ renderDueDate }}
            </p>

            <template v-if="bill?.payment_term">
                <p>
                    {{ $t('purchasesBill.detail.paymentDetail.paymentTerms') }}
                </p>
                <p>{{ $t(`general.paymentTerms.${bill?.payment_term}`) }}</p>
            </template>
        </div>
    </div>

    <data-grid
        class="mb-6"
        :columns="columns"
        :data-source="bill?.line_items ?? []"
        table-borderless
    >
        <template #column-account="{ item }">
            {{ item.chart_of_account.name }}
        </template>

        <template #column-rate="{ item }">
            {{ item?.tax_rule?.tax_rate }}
        </template>
    </data-grid>
</template>

<script setup>
import { dayDiff, humanFormat, now } from '@tenant/utils/day'
import { BILL_STATUS } from '@tenant/modules/tenant/purchases-bill/utils/constants'
import { getStatusVariant } from '@tenant/modules/tenant/purchases-bill/utils/helper'

defineProps({
    bill: {
        type: Object,
        required: true,
    },
})

const { t } = useI18n()

const supplierName = useFieldValue('supplier_name')
const dueDate = useFieldValue('due_date')
const dateOfDocument = useFieldValue('date_of_document')
const currency = useFieldValue('currency')
const status = useFieldValue('status')
const grossTotal = useFieldValue('amount_gross_total')

const getStatus = (status, dueDate) => {
    if (status === BILL_STATUS.OPEN && dayDiff(dueDate, now()) <= 0) {
        return BILL_STATUS.DUE
    }

    return status
}

const renderDueDate = computed(() => {
    if (dayDiff(dueDate.value, now()) === 0) {
        return t('purchasesBill.detail.today')
    }

    return humanFormat(dueDate.value)
})

// PAYMENT GRID
const columns = [
    {
        property: 'description',
        label: t('purchasesBill.detail.info.description'),
    },
    {
        property: 'account',
        label: t('purchasesBill.detail.info.account'),
    },
    {
        property: 'rate',
        label: t('purchasesBill.detail.info.rate'),
    },
    {
        property: 'amount',
        label: t('purchasesBill.detail.info.amount'),
        headerCellClass: 'flex justify-end',
        dataCellClass: 'flex justify-end',
    },
]
// END: PAYMENT GRID
</script>
