<template>
    <div v-if="itemize">
        <div class="mb-6 flex items-center justify-between">
            <button
                v-if="canBackSingleView"
                class="flex cursor-pointer items-center gap-2 text-primary-700"
                @click="backToSingleView"
            >
                <base-icon
                    name="line-icons:arrows:chevron-left"
                    variant="inherit"
                />
                <span class="text-sm font-medium">
                    {{ $t('general.backToSingleView') }}
                </span>
            </button>
            <base-tooltip
                v-else
                dark
                hover
                placement="top"
                :content="$t('hint.canNotBackSingleView')"
            >
                <div class="flex items-center gap-2 text-gray-300">
                    <base-icon
                        name="line-icons:arrows:chevron-left"
                        variant="inherit"
                    />
                    <span class="text-sm font-medium">
                        {{ $t('general.backToSingleView') }}
                    </span>
                </div>
            </base-tooltip>
        </div>
    </div>

    <form-field-array
        :fields="fields"
        :hide-delete="true"
        :hide-add="!itemize"
        :label-add="$t('general.addNewRow')"
        item-class="flex-col gap-y-4 items-baseline"
        :min="1"
        @on-remove-item="onRemoveItem"
        @on-add-item="onAddItem"
    >
        <template
            #default="{ item: { value, key }, idx, isReachMin, onRemoveItem }"
        >
            <form-control-group
                class="w-full flex-1"
                :class="{ 'rounded-lg border border-gray-200 p-6': itemize }"
            >
                <form-control-group-row
                    class="gap-3"
                    :class="{ 'flex-wrap': multiLine || !itemize }"
                >
                    <form-field-array-error
                        :name="`line_items[${idx}].chart_of_account_id`"
                        #="{ errorMessage }"
                    >
                        <form-detail-chart-of-account
                            v-model="value.chart_of_account_id"
                            :include-modal="false"
                            :vector-coa="
                                initialChartOfAccountIds[value.id]
                                    ? null
                                    : value.vector_chart_of_account
                            "
                            :error-message="errorMessage"
                            :input-display="
                                getChartOfAccountName(value.chart_of_account)
                            "
                            :label="
                                $t(
                                    'purchasesBill.lineItems.chartOfAccount.label'
                                )
                            "
                            :placeholder="
                                $t(
                                    'purchasesBill.lineItems.chartOfAccount.placeholder'
                                )
                            "
                            @update:modelValue="
                                checkFieldError(
                                    `line_items[${idx}].chart_of_account_id`,
                                    $t(
                                        'purchasesBill.lineItems.chartOfAccount.label'
                                    ),
                                    $event
                                )
                            "
                            @open-modal="onOpenAddModal(value)"
                            type="expense"
                        />

                        <div
                            v-if="!itemize"
                            class="flex w-full cursor-pointer items-center justify-end gap-2 text-primary-700"
                            @click="backToItemize"
                        >
                            <base-icon
                                name="line-icons:development:dataflow-04"
                                variant="inherit"
                            />
                            <span class="text-sm font-medium">
                                {{ $t('general.itemize') }}
                            </span>
                        </div>
                    </form-field-array-error>
                </form-control-group-row>

                <form-control-group-row class="gap-3">
                    <form-field-array-error
                        :name="`line_items[${idx}].description`"
                        #="{ errorMessage }"
                    >
                        <form-text-input
                            v-model="value.description"
                            :highlight="lineItemsHighlight?.[idx]?.description"
                            :label="
                                $t('purchasesBill.lineItems.description.label')
                            "
                            :error-message="errorMessage"
                            :block="false"
                        />
                    </form-field-array-error>
                </form-control-group-row>

                <form-control-group-row
                    class="gap-3"
                    :class="{ 'flex-wrap': multiLine || !itemize }"
                >
                    <div class="flex w-full">
                        <form-field-array-error
                            :name="`line_items[${idx}].amount`"
                            #="{ errorMessage }"
                        >
                            <div class="flex w-full flex-col gap-2">
                                <form-number-input
                                    v-model="value.amount"
                                    :label="
                                        $t(
                                            'purchasesBill.lineItems.amount.label'
                                        )
                                    "
                                    :error-message="errorMessage"
                                    :precision="2"
                                    :highlight="
                                        lineItemsHighlight?.[idx]?.amount
                                    "
                                    @update:modelValue="
                                        checkFieldError(
                                            `line_items.${idx}.amount`,
                                            $t(
                                                'purchasesBill.lineItems.amount.label'
                                            ),
                                            $event
                                        )
                                    "
                                />

                                <p
                                    class="ml-auto text-xs text-gray-400"
                                    v-if="
                                        exchangeRate &&
                                        convertCurrencyIso !==
                                            defaultCurrency?.iso
                                    "
                                >
                                    {{
                                        $t('general.lineItemsExchangeRate', {
                                            now: $filters.dateCustomFormat(
                                                exchangeRate.date,
                                                'YYYY-MM-DD'
                                            ),
                                            fromIso: convertCurrencyIso,
                                            toIso: defaultCurrency?.iso,
                                            factor,
                                        })
                                    }}
                                </p>
                            </div>
                        </form-field-array-error>
                    </div>

                    <div class="flex w-full flex-col">
                        <form-field-array-error
                            :name="`line_items[${idx}].tax_rule_id`"
                            #="{ errorMessage }"
                        >
                            <div class="flex w-full flex-col gap-2">
                                <form-single-select
                                    v-model="value.tax_rule_id"
                                    :error-message="errorMessage"
                                    :label="
                                        $t('purchasesBill.lineItems.tax.label')
                                    "
                                    :placeholder="
                                        $t(
                                            'purchasesBill.lineItems.tax.placeholder'
                                        )
                                    "
                                    :options="taxRules"
                                    option-value="id"
                                    :option-label="customTaxLabel"
                                    @update:modelValue="
                                        checkFieldError(
                                            `line_items.${idx}.tax_rule_id`,
                                            $t(
                                                'purchasesBill.lineItems.tax.label'
                                            ),
                                            $event
                                        )
                                    "
                                />

                                <p
                                    class="ml-auto text-xs text-gray-400"
                                    v-if="currency && !itemize"
                                >
                                    {{
                                        $t('purchasesExpense.form.taxAmount', {
                                            amount: $filters.currency(
                                                totalVat,
                                                currency?.iso
                                            ),
                                        })
                                    }}
                                </p>
                            </div>
                        </form-field-array-error>
                    </div>
                </form-control-group-row>

                <div v-if="itemize">
                    <base-button
                        outline
                        size="xl"
                        icon="line-icons:general:trash-01"
                        class="!rounded-full border-dashed !p-3 text-gray-400"
                        variant="gray"
                        :disabled="isReachMin"
                        @click="onRemoveItem(idx, key)"
                    />
                </div>
            </form-control-group>
        </template>
    </form-field-array>
    <form-control-hint-text :error-message="errorLineItems" />

    <div class="mt-3 flex justify-end gap-5">
        <form-radio
            name="taxType"
            class="w-full"
            v-model="taxType"
            :value="AMOUNT_TYPE.GROSS"
            :text="$t('general.taxIncl')"
        />
        <form-radio
            name="relationship"
            class="w-full"
            v-model="taxType"
            :value="AMOUNT_TYPE.NET"
            :text="$t('general.taxExcl')"
        />
    </div>

    <bookeeping-accounts-add-modal
        :show="showAddModal"
        @modal-close="onCloseAddModal"
        @on-finished="onFinished"
    />
</template>

<script setup>
import { useField, useFieldArray } from 'vee-validate'
import { useHighlight } from '@tenant/composables/use-highlight'
import { useEmitter } from '@tenant/composables'
import { AMOUNT_TYPE } from '@tenant/utils/constants'
import { getChartOfAccountName } from '@tenant/utils/helper'
import { useExchangeRate } from '@tenant/composables/use-exchange-rate'

const props = defineProps({
    oldLineItems: {
        type: Array,
        default: null,
    },
    multiLine: {
        type: Boolean,
        default: true,
    },
})

const emitter = useEmitter()

const { value: itemize, setValue: setItemize } = useField('itemize')
const { value: taxType } = useField('tax_type', undefined, {
    initialValue: AMOUNT_TYPE.GROSS,
})
const { errorMessage: errorLineItems } = useField('line_items')
const { remove, push, fields, replace } = useFieldArray('line_items')
const { value: lineItemsHighlight } = useHighlight('line_items')
const currency = useFieldValue('currency')
const contact = useFieldValue('contact')

const { canBackSingleView, toSingleView, toItemize } = useItemize({
    replace,
    oldLineItems: toRef(props, 'oldLineItems'),
    fields,
})

const initialChartOfAccountIds = ref({})

const onRemoveItem = (idx, key) => {
    if (fields.value.length > 1) {
        remove(idx)
    }

    nextTick(() => {
        const newLineItems = fields.value.map((i) => ({ ...i.value }))
        replace(newLineItems)

        nextTick(() => {
            emitter.emit('items-changed', { idx, key, lineItems: newLineItems })
        })
    })
}

const onAddItem = () => {
    push({
        chart_of_account_id: null,
        description: null,
        amount: null,
        tax_rule_id: contact?.value?.purchases_tax_rule_id,
    })
}

const checkFieldError = (field, fieldLabel, value) => {
    emitter.emit('check-field-error', { field, fieldLabel, value })
}

const { taxRules } = useTaxRules(['purchases'])

const customTaxLabel = (option) => `${option.name} (${option.tax_rate})`

watch(
    () => fields,
    () => {
        if (!fields || !fields.value?.length) {
            return
        }

        if (!Object.keys(initialChartOfAccountIds.value).length) {
            fields.value.forEach((item) => {
                if (!item.value?.id) {
                    return
                }

                initialChartOfAccountIds.value[item.value?.id] =
                    item.value?.chart_of_account_id
            })
        }

        fields.value.forEach((item) => {
            if (item.value.chart_of_account_id === '') {
                return
            }

            if (
                !item.value.chart_of_account_id &&
                item.value.vector_chart_of_account
            ) {
                item.value.chart_of_account_id =
                    item.value.vector_chart_of_account.id
            }
        })
    },
    { immediate: true, deep: true }
)

// COA MODAL
const { showAddModal, onCloseAddModal, onOpenAddModal, onFinished } =
    useCOAModal()
// END: COA MODAL

const backToSingleView = () => {
    setItemize(false)
    nextTick(() => {
        toSingleView()
    })
}

const backToItemize = () => {
    setItemize(true)
    nextTick(() => {
        toItemize()
    })
}

const { totalGross, totalNet } = useNetGross(fields, taxType)
const totalVat = computed(() => totalGross.value - totalNet.value)

const { value: exchangeRate } = useField('exchange_rate')

// FACTOR BASED ON EXCHANGE RATE
const { iso: convertCurrencyIso, factor, rate } = useExchangeRate(currency)
watch(
    () => rate.value,
    () => {
        exchangeRate.value = rate.value
    }
)
const { defaultCurrency } = useCurrencies()
</script>
