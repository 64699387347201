<template>
    <base-sticky-heading :title="$t('purchasesBill.create.title')">
        <template #right>
            <div class="flex gap-3">
                <base-button outline variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button
                    variant="default"
                    @click="
                        () => {
                            setDraft(true)
                            onSubmit()
                        }
                    "
                >
                    {{ $t('purchasesBill.saveAsDraft') }}
                </base-button>

                <base-button
                    @click="
                        () => {
                            setDraft(false)
                            onSubmit()
                        }
                    "
                >
                    {{ $t('purchasesBill.saveAsOpen') }}
                </base-button>
            </div>
        </template>

        <div class="flex p-6">
            <base-resizable-pane
                @resize="onPanesResize"
                @resized="state.resizing = false"
                @ready="onPanesReady"
            >
                <pane size="34" min-size="34" ref="previewContainer">
                    <purchases-bill-upload
                        :can-upload="true"
                        @textract-success="onTextractSuccess"
                        :is-resizing="state.resizing"
                        class="fixed"
                        ref="previewEl"
                    />
                </pane>

                <pane
                    min-size="34"
                    size="66"
                    class="rounded-lg border border-gray-200 bg-white p-6"
                >
                    <base-alert v-if="hasErrors" variant="warning" class="mb-6">
                        <template #title="{ titleClass }">
                            <span
                                class="mb-1 text-sm font-medium"
                                :class="titleClass"
                            >
                                {{ $t('documentExtraction.errorNotification') }}
                            </span>
                        </template>
                        <template #description="{ classes }">
                            <div
                                class="px-4 text-sm font-normal"
                                :class="classes"
                            >
                                <ul class="list-disc">
                                    <li
                                        v-for="(err, idx) in mappingErrors"
                                        :key="`general.${idx}`"
                                    >
                                        {{ err }}
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </base-alert>

                    <!-- Base information -->
                    <purchases-bill-general-informations />

                    <hr class="my-6" />

                    <purchases-bill-line-items
                        :multi-line="state.multilineForm"
                    />

                    <hr class="my-6" />

                    <!-- Summary -->
                    <purchases-bill-summary />
                </pane>
            </base-resizable-pane>
        </div>
    </base-sticky-heading>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { Pane } from 'splitpanes'
import { isEmpty } from 'lodash-es'
import { useValidateDocument } from '@tenant/modules/tenant/document-extraction/composables/use-document-extraction'

const { t } = useI18n()
const router = useRouter()
const emitter = useEmitter()

const { generalValidateSchema, lineItemsValidateSchema } = useValidateDocument()

const {
    setValues,
    handleSubmit,
    setErrors,
    errors: fieldErrors,
    setFieldValue,
} = useForm({
    validationSchema: generalValidateSchema(),
})

onMounted(() => {
    setValues({
        line_items: [
            {
                chart_of_account_id: null,
                description: null,
                amount: null,
                tax_rule_id: null,
            },
        ],
        itemize: false,
    })
})

const { uploadMultiples } = useUploadStorage()
const { setValue: setDraft } = useField('draft')

const { useApiCreate } = useApiFactory('bill')
const { execute: executeSave, code, parameters } = useApiCreate()

useHead({ title: t('purchasesBill.create.title') })
const { successNotify } = useNotification()

const onCancel = () => {
    router.push({
        name: 'purchases.bill.list',
    })
}

const onSubmit = handleSubmit(
    async ({ contact: _, currency: __, documents, ...values }) => {
        try {
            emitter.emit('set-loading', true)

            let uploadKeys = null
            if (documents) {
                uploadKeys = await uploadMultiples(documents)
            }
            const data = await executeSave(
                {
                    ...values,
                    documents: uploadKeys ?? [],
                },
                {
                    method: 'POST',
                }
            )

            successNotify({
                text: t('purchasesBill.create.success'),
            })

            await router.push({
                name: 'purchases.bill.detail',
                params: {
                    id: data?.id,
                },
            })
        } catch ({ errors }) {
            setErrors(errors)
        } finally {
            emitter.emit('set-loading', false)
        }
    }
)

const state = reactive({
    multilineForm: true,
    archivedIds: null,
    resizing: false,
})

const onTextractSuccess = async (fields) => {
    for (const [key, value] of Object.entries(fields)) {
        if (key === 'line_items') {
            if (value.length < 1) {
                continue
            }

            setFieldValue('itemize', value.length > 1)
            setFieldValue(key, value)
        } else {
            setFieldValue(key, value)
        }
    }
}

onMounted(() => {
    emitter.on('check-field-error', checkFieldError)
    emitter.on('items-changed', onItemsChanged)
})

onUnmounted(() => {
    emitter.off('check-field-error', checkFieldError)
    emitter.off('items-changed', onItemsChanged)
})

const checkFieldError = ({ field, fieldLabel, value }) => {
    if (value) {
        setErrors({ [field]: undefined })
    } else {
        setErrors({ [field]: t('validation.required', { field: fieldLabel }) })
    }
}

const validateLineItems = (line_items) => {
    line_items.forEach((lineItem, idx) => {
        try {
            lineItemsValidateSchema().validateSync(lineItem, {
                abortEarly: false,
            })
        } catch (e) {
            for (const error of e.inner) {
                setErrors({
                    [`line_items.${idx}.${error.path}`]: error.message,
                })
            }
        }
    })
}

const onItemsChanged = ({ lineItems }) => {
    const existsErrors = {}
    for (const [field] of Object.entries(fieldErrors.value)) {
        const match = field.match(/line_items\[(\d+)]/)
        if (!match) {
            continue
        }

        existsErrors[field] = undefined
    }

    setErrors(existsErrors)
    nextTick(() => {
        validateLineItems(lineItems)
    })
}

const hasErrors = computed(() => {
    return !isEmpty(fieldErrors.value)
})

const mappingErrors = computed(() => {
    const errors = []

    for (const [field, message] of Object.entries(fieldErrors.value)) {
        const match = field.match(/line_items\[(\d+)]/)
        if (!match) {
            errors.push(message)
            continue
        }

        errors.push(t('validation.lineItems'))
    }

    return [...new Set(errors)]
})

const previewContainer = ref(null)
const previewEl = ref(null)

const onPanesResize = (event) => {
    const formSize = event[1]?.size || 0
    state.multilineForm = formSize < 40
    state.resizing = true

    const parentWidth = previewContainer.value?.$parent.container.clientWidth
    const previewWidth = (parentWidth * event[0].size) / 100
    previewEl.value.$el.style.width = `${previewWidth - 8}px`
}

const onPanesReady = () => {
    const parentWidth = previewContainer.value?.$parent.container.clientWidth
    const previewWidth = (parentWidth * previewContainer.value.sizeNumber) / 100
    previewEl.value.$el.style.width = `${previewWidth - 8}px`
}
</script>
