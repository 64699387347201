import { useApiFactory } from '@tenant/composables'
import { dayDiff, now } from '@tenant/utils/day'
import { BILL_STATUS } from '@tenant/modules/tenant/purchases-bill/utils/constants'

export const useGetBills = (queries = {}, shouldWatch = true) => {
    const { useApiSearch, entities } = useApiFactory('bill')

    const { loading, execute, executeRefreshSearch } = useApiSearch(
        {
            ...queries,
        },
        shouldWatch
    )

    return {
        entities,
        loading,
        execute,
        executeRefreshSearch,
    }
}

export const useBillStatus = () => {
    const getStatus = (status, dueDate) => {
        if (status === BILL_STATUS.OPEN && dayDiff(dueDate, now()) < 0) {
            return BILL_STATUS.DUE
        }

        return status
    }

    const getStatusText = (item) => {
        const { t } = useI18n()
        if (!item?.status) return

        const status = getStatus(item?.status, item.due_date)

        if (status === 'due') {
            return t(
                `general.statuses.${status}`,
                Math.abs(dayDiff(item.due_date, now()))
            )
        }

        return t(`general.statuses.${status}`)
    }

    return {
        getStatus,
        getStatusText,
    }
}
