<template>
    <div class="flex flex-col text-md font-medium" v-if="currency">
        <div class="flex border-b py-2.5">
            <div class="flex-1 text-left">
                {{ $t('purchasesBill.edit.summary.netAmount') }}
            </div>
            <div class="flex-1 text-right">
                {{ $filters.currency(amountNet, currency.iso) }}
            </div>
        </div>

        <div
            class="flex border-b py-2.5"
            v-for="(value, key) in vatAmounts"
            :key="key"
        >
            <div class="flex-1 text-left">
                {{ $t('purchasesBill.edit.summary.tax') }}
                <span v-if="value.taxRule">{{ value.taxRule?.tax_rate }}</span>
            </div>
            <div class="flex-1 text-right">
                {{ $filters.currency(value.vat, currency.iso) }}
            </div>
        </div>

        <div class="flex border-b py-2.5 font-bold">
            <span class="flex-1 text-left text-md"> Balance Due </span>
            <span class="flex-1 text-right">
                {{ $filters.currency(unpaidAmount, currency.iso) }}
            </span>
        </div>

        <div class="flex py-2.5 font-bold">
            <span class="flex-1 text-left text-md">{{
                $t('purchasesBill.edit.summary.total')
            }}</span>
            <span class="flex-1 text-right">
                {{ $filters.currency(amountGross, currency.iso) }}
            </span>
        </div>
    </div>
</template>

<script setup>
const { fields } = useFieldArray('line_items')
const taxType = useFieldValue('tax_type')

const { totalGross, totalNet, vatAmounts } = useNetGross(fields, taxType)

const { value: amountNet } = useField('amount_net_total')
const { value: amountGross } = useField('amount_gross_total')
const { value: unpaidAmount } = useField('unpaid_amount')
const currency = useFieldValue('currency')

// Update `amount_net_total` and `amount_gross_total` fields based on computed values from `useInvoice`
watch(totalGross, () => (amountGross.value = totalGross.value), {
    immediate: true,
})
watch(totalNet, () => (amountNet.value = totalNet.value), {
    immediate: true,
})
</script>
