<template>
    <form-text-input
        class="grow"
        size="sm"
        icon-name="line-icons:general:search-lg"
        :model-value="filterValues.name"
        :placeholder="$t('purchasesBill.searchByReferenceNumber')"
        @input="onChange($event.target.value)"
    />
</template>

<script setup>
import { useDebounceFn } from '@vueuse/core'

const queries = {
    name: 'q',
}
const { filterValues, updateFilter } = useFilters(queries)

const onChange = useDebounceFn(
    (value) => updateFilter(queries.name, value),
    300
)
</script>
