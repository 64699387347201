<template>
    <base-modal
        size="xl"
        class="w-[960px]"
        close
        @modal-close="$emit('modal-close')"
        :show="show"
        has-separator
    >
        <template #modal-header>
            <h4 class="mb-1 text-lg font-medium text-gray-900">
                {{
                    $t('purchasesBill.applyCreditModal.title', {
                        number: bill.document_number,
                    })
                }}
            </h4>
        </template>

        <data-grid
            :columns="columns"
            :data-source="lineItems"
            :loading="loading"
            :key="updateKey"
        >
            <template #column-number="{ item }">
                {{ item.sale_document.number }}
            </template>

            <template #column-date="{ item }">
                {{ $filters.dateHumanFormat(item.date_of_credit_note) }}
            </template>

            <template #column-amount="{ item }">
                {{
                    $filters.symbolCurrency(
                        item.sale_document.amount_gross_total,
                        item.sale_document.currency
                    )
                }}
            </template>

            <template #column-unpaidAmount="{ item }">
                {{
                    $filters.symbolCurrency(
                        item.sale_document.unpaid_amount,
                        item.sale_document.currency
                    )
                }}
            </template>
            <template #column-creditAmount="{ item }">
                <form-number-input
                    :model-value="item.credit_amount"
                    @change="onChangeAmount($event, item)"
                    size="sm"
                    :precision="2"
                />
            </template>
        </data-grid>

        <p class="mb-2.5 mt-5 text-right text-sm font-medium text-gray-900">
            {{
                $t('purchasesBill.applyCreditModal.amountToCreditText', {
                    amount: $filters.numberFormat(creditedAmount),
                })
            }}
        </p>

        <p class="text-right font-medium text-gray-900">
            {{
                $t('purchasesBill.applyCreditModal.billBalance', {
                    amount: $filters.numberFormat(remainingCredit),
                })
            }}
        </p>

        <template #modal-footer>
            <div class="flex justify-end gap-3">
                <base-button
                    outline
                    variant="default"
                    @click="$emit('modal-close')"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    :disabled="!creditedAmount"
                    :loading="isApplyingCredit"
                    @click="onApplyCredit"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { CREDIT_NOTE_STATUS } from '@tenant/modules/tenant/credit-notes/utils/constants'
import { filter, map, fromPairs } from 'lodash-es'
import { useCreditCalculator } from '@tenant/composables/use-credit-calculator'

const emit = defineEmits(['modal-close', 'modal-save'])
const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },
    bill: {
        type: [Object, null],
        default: () => ({}),
    },
})

const { t } = useI18n()
const { errorNotify, successNotify } = useNotification()

const loading = ref(false)
const { useApiSearch, entities } = useApiFactory('vendor-credits')

const lineItems = ref([])

onMounted(async () => {
    loading.value = true
    try {
        await useApiSearch(
            {
                limit: 10,
                filter: {
                    'saleDocument.status': CREDIT_NOTE_STATUS.OPEN,
                    'saleDocument.contact.contact_id':
                        props.bill.contact.contact_id,
                },
            },
            true,
            true
        ).execute()

        if (!entities.value?.data?.length) return

        entities.value.data.forEach((entity) => {
            lineItems.value.push({
                ...entity,
                credit_amount: 0,
            })
        })
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        loading.value = false
    }
})

const columns = [
    {
        property: 'number',
        label: t('purchasesBill.applyCreditModal.creditNoteNoColumn'),
    },
    {
        property: 'date',
        label: t('purchasesBill.applyCreditModal.dateColumn'),
    },
    {
        property: 'amount',
        label: t('purchasesBill.applyCreditModal.creditAmountColumn'),
    },
    {
        property: 'unpaidAmount',
        label: t('purchasesBill.applyCreditModal.creditsAvailableColumn'),
    },
    {
        property: 'creditAmount',
        label: t('purchasesBill.applyCreditModal.amountToCreditColumn'),
        headerCellClass: 'w-44',
    },
]

const { creditedAmount, remainingCredit, updateKey, onChangeAmount } =
    useCreditCalculator(props.bill?.unpaid_amount, lineItems.value)

const { execute: executeApplyCredit, loading: isApplyingCredit } = useApi(
    '/api/bill/{id}/apply-vendor-credits',
    'POST'
)
const onApplyCredit = async () => {
    const request = {
        vendor_credits: fromPairs(
            map(
                filter(lineItems.value, (item) => item.credit_amount > 0),
                (item) => [item.id, item.credit_amount]
            )
        ),
    }
    try {
        await executeApplyCredit({
            params: {
                id: props.bill.id,
            },
            data: request,
        })

        successNotify({
            text: t('purchasesBill.applyCreditModal.applySuccessMsg'),
        })

        emit('modal-save')
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    }
}
</script>
