<template>
    <div>
        <base-alert
            v-if="errorDocuments"
            class="mb-5"
            variant="danger"
            :title="$t('general.error')"
            :description="errorDocuments"
        />
        <form-file-server-upload
            v-model="documents"
            container-class="min-h-[840px] !justify-start pt-12"
            :description="
                $t(
                    'purchasesBill.edit.expenditureDocument.fileUploadDescription'
                )
            "
            :acceptedFiles="['application/pdf', 'image/*']"
            :on-custom-upload-files="handleUploadFiles"
            :custom-name="customFileName"
            :custom-path="customFilePath"
            :editing="canUpload"
            v-slot="{
                files,
                getUploadFileByIdx,
                onRemoveFile,
                triggerDropdown,
                getUrlByFile,
                getTypeByFile,
                hasFiles,
            }"
        >
            <div
                class="overflow-hidden rounded-lg border border-gray-200"
                v-if="hasFiles"
            >
                <div class="flex items-center justify-between bg-gray-100 p-4">
                    <div class="flex items-center gap-x-5">
                        <base-icon
                            name="line-icons:arrows:chevron-left"
                            variant="gray"
                            class="cursor-pointer"
                            :disabled="viewingPage === 1"
                            @click="previousPage"
                        />
                        <span class="text-sm font-medium text-gray-500">
                            {{ viewingPage }}/{{ files.length }}
                        </span>
                        <base-icon
                            name="line-icons:arrows:chevron-right"
                            variant="gray"
                            class="cursor-pointer"
                            :disabled="viewingPage === files.length"
                            @click="nextPage"
                        />
                    </div>
                    <div class="flex items-center gap-x-5">
                        <base-icon
                            v-if="canUpload"
                            name="line-icons:general:plus"
                            variant="gray"
                            class="cursor-pointer"
                            :disabled="loadingTextract"
                            @click="triggerDropdown"
                        />

                        <base-icon
                            v-if="canUpload"
                            name="line-icons:general:trash-01"
                            variant="gray"
                            class="cursor-pointer"
                            :disabled="loadingTextract"
                            @click="
                                onRemoveFile(
                                    getUploadFileByIdx(viewingPage - 1)
                                )
                            "
                        />
                    </div>
                </div>
                <div
                    class="relative h-[calc(100vh-296px)] p-2"
                    v-show="!isResizing"
                >
                    <span
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                        v-if="loadingTextract"
                    >
                        <base-loading size="xl" />
                    </span>

                    <template v-else>
                        <div
                            v-if="
                                isPdfFile(
                                    getTypeByFile(
                                        getUploadFileByIdx(viewingPage - 1)
                                    )
                                )
                            "
                            class="relative mx-auto h-full w-full"
                        >
                            <object
                                :data="
                                    getUrlByFile(
                                        getUploadFileByIdx(viewingPage - 1)
                                    )
                                "
                                type="application/pdf"
                                class="flex h-full w-full items-center justify-center"
                            >
                                <h5
                                    class="text-center"
                                    v-html="
                                        $t('general.unableToDisplayPDF', {
                                            link: getUrlByFile(
                                                getUploadFileByIdx(
                                                    viewingPage - 1
                                                )
                                            ),
                                        })
                                    "
                                />
                            </object>
                        </div>

                        <div v-else class="relative mx-auto h-full w-fit">
                            <template v-if="viewingPage === 1">
                                <template
                                    v-for="highlight in highlights"
                                    :key="highlight.field"
                                >
                                    <document-extraction-textract-highlight
                                        v-if="highlight?.bounding"
                                        :bounding="highlight.bounding"
                                        @change="
                                            (state) =>
                                                onHighlight(
                                                    highlight.field,
                                                    state
                                                )
                                        "
                                    />
                                </template>
                            </template>

                            <img
                                :src="
                                    getUrlByFile(
                                        getUploadFileByIdx(viewingPage - 1)
                                    )
                                "
                                class="h-full"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </form-file-server-upload>
    </div>
</template>

<script setup>
import { useUploadFile } from '@tenant/composables/use-upload-file'
import { useHighlight } from '@tenant/composables/use-highlight'
import {
    convertTextractToFields,
    convertTextractToHighlight,
} from '@tenant/utils/helper'
import { useField } from 'vee-validate'

const props = defineProps({
    editing: {
        type: Boolean,
        default: false,
    },

    canUpload: {
        type: Boolean,
        default: true,
    },
    isResizing: {
        type: Boolean,
        default: false,
    },
})

const { setHighlightValue } = useHighlight()
const { executeSingle, loading: loadingTextract } = useUploadFile(
    '/api/purchase/extract-document/bill'
)

const emit = defineEmits(['textract-success'])

const highlights = ref([])
const viewingPage = ref(1)
const { value: documents, errorMessage: errorDocuments } = useField('documents')

watch(
    () => documents.value,
    (value) => {
        if (viewingPage.value <= value.length) {
            return
        }

        viewingPage.value = Math.max(value.length, 1)
    }
)

const onHighlight = (field, value) => setHighlightValue(field, value)

const nextPage = () => {
    viewingPage.value = viewingPage.value + 1
}

const previousPage = () => {
    viewingPage.value = viewingPage.value - 1
}

const handleUploadFiles = async (files) => {
    if (props.editing) {
        return
    }

    if (documents.value && documents.value.length > 0) {
        return
    }

    const response = await executeSingle(files[0], 'document')

    highlights.value = convertTextractToHighlight(response)
    emit('textract-success', convertTextractToFields(response))
}

const isPdfFile = (fileType) => {
    return fileType === 'application/pdf' || fileType.includes('pdf')
}

const customFileName = (file) => {
    return file.full_name
}

const customFilePath = (file) => {
    return file.preview?.path
}
</script>
