<template>
    <base-modal
        close
        @modal-close="closeModal"
        :show="show"
        size="xxl"
        :content-padding="false"
        :custom-content-class="{
            'min-h-[300px]': true,
        }"
    >
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('purchasesBill.finish.book_payment') }}
            </span>
        </template>

        <div class="grid grid-cols-7 gap-5 border-b px-6 pb-3">
            <div>
                <p class="text-sm font-medium text-gray-900">
                    {{ $t('purchasesBill.finish.document_number') }}
                </p>
                <span
                    v-if="item?.document_number"
                    class="text-sm text-gray-500"
                >
                    {{ item?.document_number }}
                </span>
            </div>

            <div>
                <p class="text-sm font-medium text-gray-900">
                    {{ $t('purchasesBill.finish.supplier') }}
                </p>
                <span class="text-sm text-gray-500">
                    {{ item.supplier_name }}
                </span>
            </div>

            <div>
                <p class="text-sm font-medium text-gray-900">
                    {{ $t('purchasesBill.finish.date_of_document') }}
                </p>
                <span
                    v-if="item?.date_of_document"
                    class="text-sm text-gray-500"
                >
                    {{ $filters.dateShortFormat(item.date_of_document) }}
                </span>
            </div>

            <div class="col-span-2">
                <p class="text-sm font-medium text-gray-900">
                    {{ $t('transaction.bookPaymentModal.unpaidAmount') }}
                </p>
                <span class="text-sm font-semibold text-danger-600" v-if="item">
                    {{
                        $filters.currency(
                            item.amount_gross_total,
                            item.currency.iso
                        )
                    }}
                    /
                    {{
                        $filters.currency(item.unpaid_amount, item.currency.iso)
                    }}</span
                >
            </div>

            <div class="col-span-2">
                <form-single-select
                    v-model="paymentAccount"
                    :error-message="errorPaymentAccount"
                    :options="paymentAccounts"
                    :show-clear="false"
                    option-label="name"
                    option-value="id"
                    :placeholder="
                        $t(
                            'transaction.bookPaymentModal.paymentAccountPlaceholder'
                        )
                    "
                />
            </div>
        </div>

        <template v-if="selectedPaymentAccount?.type === ACCOUNT_TYPES.ONLINE">
            <div class="p-6">
                <transaction-filter-panel
                    :is-negative="true"
                    @filter-update="onFilterUpdate"
                />
            </div>

            <div>
                <transaction-payment-list
                    :is-negative="true"
                    :payment-account-id="paymentAccount"
                    @transaction-select="onTransactionSelect"
                    :queries="filterQueries"
                />
            </div>
        </template>

        <template v-else>
            <div class="flex w-full justify-end border-b p-6">
                <div class="flex w-1/2 gap-5">
                    <form-date-picker
                        size="md"
                        :label="
                            $t('transaction.bookPaymentModal.paymentDateLabel')
                        "
                        single
                        v-model="paymentDate"
                        :error-message="errorPaymentDate"
                        :full-width="true"
                        auto-apply
                    />

                    <form-text-input
                        :label="$t('transaction.bookPaymentModal.amountLabel')"
                        size="md"
                        v-model="amount"
                        :error-message="errorAmount"
                        :full-width="true"
                    />
                </div>
            </div>
        </template>

        <template #modal-footer>
            <div class="flex justify-end gap-3">
                <base-button
                    outline
                    variant="default"
                    :disabled="isBookingPayment"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    :disabled="
                        !paymentAccount ||
                        (!paymentTransaction &&
                            selectedPaymentAccount?.type ===
                                ACCOUNT_TYPES.ONLINE)
                    "
                    :loading="isBookingPayment"
                    @click="onSubmit"
                >
                    {{ $t('transaction.bookPaymentModal.bookBtn') }}
                </base-button>
            </div>
        </template>
    </base-modal>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { ACCOUNT_TYPES, DATE_SERVER_FORMAT } from '@tenant/utils/constants'
import { dayjsFormat, now } from '@tenant/utils/day'

const emit = defineEmits(['modal-close', 'modal-confirm'])

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },
    item: {
        type: [Object, null],
        default: () => ({}),
    },
})

const closeModal = () => {
    emit('modal-close')
}

const { handleSubmit, setErrors } = useForm()

const {
    value: paymentDate,
    errorMessage: errorPaymentDate,
    resetField: resetPaymentDate,
} = useField('payment_date')
const {
    value: amount,
    errorMessage: errorAmount,
    resetField: resetAmount,
} = useField('amount', undefined, {
    initialValue: props.item?.unpaid_amount ?? 0,
})
const { value: paymentAccount, errorMessage: errorPaymentAccount } =
    useField('payment_account_id')
const { value: paymentTransaction } = useField('payment_account_transaction_id')

// PAYMENT ACCOUNTS
const { paymentAccounts } = usePaymentAccounts(props.item.currency_id)
watch(
    () => paymentAccounts.value,
    () => {
        if (paymentAccounts.value.length) {
            paymentAccount.value = paymentAccounts.value[0].id
        }
    },
    { immediate: true }
)

const selectedPaymentAccount = computed(() => {
    return paymentAccounts.value.find(
        (item) => item.id === paymentAccount.value
    )
})

// Handle set payment date and amount when payment account is changed
watch(
    () => selectedPaymentAccount.value,
    (value) => {
        if ([ACCOUNT_TYPES.BANK_FEED].includes(value?.type)) {
            amount.value = props.item?.unpaid_amount ?? 0
            paymentDate.value = dayjsFormat(now(), DATE_SERVER_FORMAT)
        } else {
            resetAmount()
            resetPaymentDate()
        }
    }
)

const onTransactionSelect = (value) => {
    paymentTransaction.value = value
}

// HANDLE BOOK PAYMENT
const isBookingPayment = ref(false)
const {
    execute: bookOutgoingPayment,
    code,
    parameters,
} = useApi('/api/book-outgoing-payment', 'POST')

const onSubmit = handleSubmit(async (values) => {
    try {
        isBookingPayment.value = true

        await bookOutgoingPayment({
            data: {
                booked_id: props.item.id,
                ...values,
                outgoing_type: 'bill',
                payment_account_transaction_id: paymentTransaction.value,
            },
        })

        emit('modal-confirm')
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        isBookingPayment.value = false
    }
})
// END: HANDLE BOOK PAYMENT

const filterQueries = ref(null)
const onFilterUpdate = (values) => {
    filterQueries.value = values
}
</script>
