<template>
    <form-control-group>
        <form-control-group-row>
            <!-- Document number -->
            <form-text-input
                class="flex-1"
                v-model="documentNumber"
                :error-message="errorDocumentNumber"
                :highlight="documentNumberHighlight"
                :label="$t('purchasesBill.edit.documentNumber.label')"
                :placeholder="
                    $t('purchasesBill.edit.documentNumber.placeholder')
                "
            />

            <form-contact-select
                class="flex-1"
                v-model="contactId"
                :error-message="errorContactId || errorSupplier"
                :highlight="supplierNameHighlight"
                :label="$t('purchasesBill.edit.supplier.label')"
                :placeholder="$t('purchasesBill.edit.supplier.placeholder')"
                :input-display="getContactName(contact)"
                :queries="contactQueries"
                @on-contact-changed="onContactChange"
            />
        </form-control-group-row>

        <form-control-group-row class="flex-wrap gap-3">
            <form-date-picker
                class="flex-1 basis-48"
                single
                auto-apply
                v-model="documentDate"
                :error-message="errorDateOfDocument"
                :highlight="documentDateHighlight"
                :label="$t('purchasesBill.edit.documentDate.label')"
            />

            <form-date-picker
                single
                auto-apply
                class="flex-1 basis-48"
                v-model="dueDate"
                @update:model-value="onDueDateChange"
                :error-message="errorDueDate"
                :highlight="dueDateHighlight"
                :label="$t('purchasesBill.edit.dueDate.label')"
            />

            <form-single-select
                class="flex-1 basis-48"
                v-model="paymentTerm"
                :error-message="errorPaymentTerm"
                :label="$t('purchasesBill.edit.paymentTerms.label')"
                :options="paymentTerms"
            />
        </form-control-group-row>

        <form-control-group-row>
            <form-single-select
                v-model="currencyId"
                :error-message="errorCurrencyId"
                :label="$t('general.currency')"
                :options="currencies"
                option-value="value"
                option-label="iso"
                :searchable-fields="['iso', 'label']"
                @change="onCurrencyChange"
            />
        </form-control-group-row>
    </form-control-group>
</template>

<script setup>
import { dateFromPaymentTerm, getContactName } from '@tenant/utils/helper'
import { DATE_SERVER_FORMAT, PAYMENT_TERMS } from '@tenant/utils/constants'
import { dayjsFormat, now } from '@tenant/utils/day'
import { useField, useFieldArray } from 'vee-validate'

const { defaultCurrency, currencies } = useCurrencies()

const { value: documentNumberHighlight } = useHighlight('document_number')
const { value: documentDateHighlight } = useHighlight('date_of_document')
const { value: supplierNameHighlight } = useHighlight('supplier_name')
const { value: dueDateHighlight } = useHighlight('due_date')

const { value: documentNumber, errorMessage: errorDocumentNumber } =
    useField('document_number')
const { value: documentDate, errorMessage: errorDateOfDocument } =
    useField('date_of_document')
const { value: supplier, errorMessage: errorSupplier } =
    useField('supplier_name')
const { value: contact } = useField('contact')
const { value: contactId, errorMessage: errorContactId } =
    useField('contact_id')
const { value: dueDate, errorMessage: errorDueDate } = useField('due_date')
const { value: paymentTerm, errorMessage: errorPaymentTerm } = useField(
    'payment_term',
    {},
    { initialValue: PAYMENT_TERMS.DUE_ON_RECEIPT }
)
const {
    value: currencyId,
    errorMessage: errorCurrencyId,
    setValue: setCurrencyId,
} = useField('currency_id')

const { setValue: setCurrency } = useField('currency')
const { fields, update } = useFieldArray('line_items')

onMounted(() => {
    // Set document date as today if there's no value
    if (!documentDate.value) {
        documentDate.value = dayjsFormat(now(), DATE_SERVER_FORMAT)
    }
})

watch(
    () => defaultCurrency.value,
    (value) => {
        setCurrencyId(value?.value)
        setCurrency(value)
    },
    { immediate: true }
)

const onContactChange = ({ item, id }) => {
    if (!item) {
        return
    }
    contact.value = item
    contactId.value = id
    supplier.value = getContactName(item)

    if (item?.currency) {
        setCurrencyId(item.currency.id)
        setCurrency(item.currency)
    }

    // Set default configuration from contact to bill
    if (contact.value?.purchases_payment_term) {
        paymentTerm.value = contact.value.purchases_payment_term
    }

    fields.value.map((field, idx) => {
        update(idx, {
            ...field.value,
            tax_rule_id: contact.value.purchases_tax_rule_id,
        })
    })
}

const contactQueries = ref({})

watch(
    () => contactId.value,
    (value) => {
        if (!value) {
            contactQueries.value = {}
        } else {
            contactQueries.value = {
                'filter[id]': value,
            }
        }
    }
)

// HANDLE PAYMENT TERMS
const { t } = useI18n()
const paymentTerms = computed(() => {
    return Object.values(PAYMENT_TERMS).map((item) => ({
        value: item,
        label: t(`general.paymentTerms.${item}`),
    }))
})

watchEffect(() => {
    if (paymentTerm.value === PAYMENT_TERMS.CUSTOM) return
    dueDate.value = dateFromPaymentTerm(documentDate.value, paymentTerm.value)
})

const onDueDateChange = () => {
    paymentTerm.value = PAYMENT_TERMS.CUSTOM
}

const onCurrencyChange = (_, currency) => {
    if (!currency) {
        return
    }

    setCurrency(currency)
}
// END: HANDLE PAYMENT TERMS
</script>
