<template>
    <base-compact-list
        :items="bills"
        :is-loading="isLoading"
        :initial-selected-item="bill"
        selectable
        @item-click="onSidebarItem"
        @load-more="onLoadMore"
        @item-check="onItemCheck"
        :initialize="initializeList"
        header-classes="py-3.5"
    >
        <template #header="{ checkedItems }">
            <template v-if="checkedItems.length">
                <div class="flex items-center gap-2.5">
                    <span class="text-sm text-gray-700">
                        {{
                            $t(
                                'purchasesBill.detail.selectedText',
                                checkedItems.length
                            )
                        }}
                    </span>
                </div>

                <base-context-menu>
                    <base-context-menu-item
                        v-if="$acl.can('delete_bills')"
                        icon="line-icons:general:trash-01"
                        @click="onDeleteBills"
                    >
                        {{ $t('general.delete') }}
                    </base-context-menu-item>
                </base-context-menu>
            </template>

            <template v-else>
                <base-dropdown size="sm" variant="default" class="bg-white">
                    <template #btn-content>
                        {{ statusText }}

                        <base-icon
                            name="line-icons:arrows:chevron-down"
                            variant="inherit"
                        />
                    </template>

                    <template #default="{ toggleDropdown }">
                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange()
                                }
                            "
                        >
                            {{
                                $t(
                                    'purchasesBill.detail.sidebar.filter.allBills',
                                    checkedItems.length
                                )
                            }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(BILL_STATUS.DRAFT)
                                }
                            "
                        >
                            {{
                                $t('purchasesBill.detail.sidebar.filter.draft')
                            }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(BILL_STATUS.OPEN)
                                }
                            "
                        >
                            {{ $t('purchasesBill.detail.sidebar.filter.open') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(BILL_STATUS.DUE)
                                }
                            "
                        >
                            {{ $t('purchasesBill.detail.sidebar.filter.due') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(BILL_STATUS.PART_PAID)
                                }
                            "
                        >
                            {{
                                $t(
                                    'purchasesBill.detail.sidebar.filter.partPaid'
                                )
                            }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(BILL_STATUS.PAID)
                                }
                            "
                        >
                            {{ $t('purchasesBill.detail.sidebar.filter.paid') }}
                        </base-dropdown-item>
                    </template>
                </base-dropdown>

                <base-button
                    v-if="$acl.can('create_bills')"
                    icon="line-icons:general:plus"
                    :label="$t('general.new')"
                    size="sm"
                    @click="goToBillCreate"
                />
            </template>
        </template>

        <template #item-content="{ item, clickItem }">
            <div class="w-full" @click="clickItem(item)">
                <div class="mb-1 flex w-full items-center justify-between">
                    <p class="text-sm text-gray-700">
                        {{ truncateString(item.supplier_name, 25) }}
                    </p>

                    <p class="text-sm text-gray-700">
                        {{
                            $filters.currency(
                                item.amount_gross_total,
                                item.currency?.iso
                            )
                        }}
                    </p>
                </div>

                <div class="flex w-full items-center justify-between">
                    <p class="text-xs">
                        <span class="text-primary-600">
                            {{ item.document_number }}
                        </span>

                        <span class="px-2.5 text-gray-300">|</span>

                        <span class="text-gray-500">{{
                            $filters.dateHumanFormat(item?.due_date)
                        }}</span>
                    </p>

                    <partial-status
                        :status="getStatus(item.status, item.due_date)"
                        variant="small"
                    >
                        <template #text>
                            {{ getStatusText(item) }}
                        </template>
                    </partial-status>
                </div>
            </div>
        </template>
    </base-compact-list>

    <bills-delete-failure-modal
        :show="showCannotDeleteModal"
        @modal-close="showCannotDeleteModal = false"
    />
</template>

<script setup>
import { isEqual, uniqBy } from 'lodash-es'
import { useApiFactory, useFilters } from '@tenant/composables'
import { useInfiniteQuery } from '@tanstack/vue-query'
import { truncateString } from '@tenant/utils/helper'
import { BILL_STATUS } from '@tenant/modules/tenant/purchases-bill/utils/constants'
import { dayjsFormat, now } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'
import { useBillStatus } from '@tenant/modules/tenant/purchases-bill/composables/use-bill'

const { getStatus, getStatusText } = useBillStatus()

const props = defineProps({
    bill: {
        type: Object,
        default: () => null,
    },
})

const route = useRoute()
const router = useRouter()
const { successNotify, errorNotify } = useNotification()

// INFINITE SCROLL LIST
const { useApiSearch } = useApiFactory('bill/cursor')
const { execute } = useApiSearch({}, false)

const { execute: executeDeleteBills } = useApi('/api/bill', 'DELETE')

const showCannotDeleteModal = ref(false)

const {
    data: bills,
    isFetching: isLoading,
    fetchNextPage: fetchMore,
    hasNextPage: hasMore,
    refetch,
} = useInfiniteQuery({
    queryKey: ['bills'],
    queryFn: ({ pageParam }) => {
        return execute({
            cursor: pageParam,
            limit: 20,
            ...route.query,
        })
    },
    getNextPageParam: ({ next_cursor }) => next_cursor,
    select: (res) =>
        uniqBy(
            res?.pages?.flatMap((x) => x?.data),
            (x) => x?.id
        ),
})

const onLoadMore = () => {
    if (hasMore.value) {
        fetchMore()
    }
}

// END: INFINITE SCROLL LIST

const onSidebarItem = (item) => {
    router.push({
        name: 'purchases.bill.detail',
        params: { id: item.id },
        query: { ...route.query },
    })
}

const goToBillCreate = () => {
    router.push({ name: 'purchases.bill.create' })
}

const onDeleteBills = async () => {
    try {
        await executeDeleteBills({
            data: {
                ids: checkedItems.value.map((item) => item.id),
            },
        })

        successNotify({
            title: t('purchasesBill.deleteSuccess'),
        })

        await router.push({
            name: 'purchases.bill',
        })
    } catch ({ hasErrors, errorMessage }) {
        showCannotDeleteModal.value = true
        if (hasErrors) {
            errorNotify({
                title: errorMessage,
            })
        }
    }
}

// FILTER
const queries = {
    statuses: 'statuses',
    due_date: 'filter.due_date',
}
const { filterValues, updateFilters } = useFilters(queries)
const status = ref(null)
watch(
    () => filterValues.value,
    (value, oldValue) => {
        if (isEqual(value, oldValue)) return
        refetch()
    }
)
watch(
    () => props?.bill?.status,
    () => {
        refetch()
    }
)

onMounted(() => {
    initStatus()
})

const initStatus = () => {
    const { statuses, due_date } = filterValues.value
    if (!statuses) {
        return
    }

    if (Array.isArray(statuses) && statuses.length > 0) {
        const values = statuses[0] || []

        if (values.includes(BILL_STATUS.OPEN) && due_date?.[0] === 'DATE <') {
            status.value = BILL_STATUS.DUE
            return
        }
    }

    status.value = statuses[0]
}

const filterChange = (value = null) => {
    const filters = {
        [queries.statuses]: !value ? [] : [value],
        [queries.due_date]: null,
    }

    if (value === BILL_STATUS.OPEN) {
        filters[queries.statuses] = [BILL_STATUS.OPEN]
        filters[queries.due_date] = [
            'DATE >=',
            dayjsFormat(now(), DATE_SERVER_FORMAT),
        ]
    }

    if (value === BILL_STATUS.DUE) {
        filters[queries.statuses] = [BILL_STATUS.OPEN]
        filters[queries.due_date] = [
            'DATE <',
            dayjsFormat(now(), DATE_SERVER_FORMAT),
        ]
    }

    status.value = value

    updateFilters(filters)
}

const { t } = useI18n()
const statusText = computed(() => {
    if (status.value === BILL_STATUS.DUE) {
        return t('purchasesBill.detail.sidebar.filter.due')
    }

    if (status.value === BILL_STATUS.OPEN) {
        return t('purchasesBill.detail.sidebar.filter.open')
    }

    if (status.value === BILL_STATUS.PART_PAID) {
        return t('purchasesBill.detail.sidebar.filter.partPaid')
    }

    if (status.value === BILL_STATUS.PAID) {
        return t('purchasesBill.detail.sidebar.filter.paid')
    }

    if (status.value === BILL_STATUS.DRAFT) {
        return t('purchasesBill.detail.sidebar.filter.draft')
    }

    return t('purchasesBill.detail.sidebar.filter.allBills')
})
// END: FILTER

const checkedItems = ref([])
const onItemCheck = ({ checkedItems: items }) => {
    checkedItems.value = items
}

const initializeList = ref(false)
</script>
